import * as React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';
import { useIdleTimer } from 'react-idle-timer';

import { RequireAuth, ErrorComponent, Loading } from '@components/index';

const DataPortal = React.lazy(() => import('src/features/dataPortal/DataPortal'));
const StudyManager = React.lazy(() => import('src/features/studyManager/StudyManager'));
const Login = React.lazy(() => import('src/features/users/Login'));
const OperationsOverview = React.lazy(() => import('@features/operationsOverview/OperationsOverview'));
const LabPortal = React.lazy(() => import('@features/labPortal/LabPortal'));
const ResultExplorer = React.lazy(() => import('@features/resultExplorer/ResultExplorer'));
const Datahub = React.lazy(() => import('@features/datahub/Datahub'));
const UploadData = React.lazy(() => import('@features/uploadData/UploadData'));

export const AppRouter = () => (
  <BrowserRouter>
    <div className="flex h-screen flex-col">
      <AppRoutes />
    </div>
  </BrowserRouter>
);

const AppRoutes = () => {
  const { initialized, keycloak } = useKeycloak();

  /////////////////////////////////////////////////////////////////////////////
  // Log out user after 15 minutes of inactivity
  /////////////////////////////////////////////////////////////////////////////
  const handleOnIdle = () => {
    if (keycloak.authenticated) {
      keycloak.logout();
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    crossTab: true,
    debounce: 500,
  });
  /////////////////////////////////////////////////////////////////////////////

  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to={keycloak.authenticated ? 'study-manager' : '/users/login'} replace />} />

      <Route
        path="/users/login"
        element={
          <React.Suspense fallback={<Loading />}>
            <Login />
          </React.Suspense>
        }
      />

      {/* Lazy route for code splitting */}
      <Route
        path="study-manager/*"
        element={
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <StudyManager />
            </RequireAuth>
          </React.Suspense>
        }
      />

      {/* Lazy route for code splitting */}
      <Route
        path="data-portal/*"
        element={
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <DataPortal />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="lab-portal/*"
        element={
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <LabPortal />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="result-explorer/*"
        element={
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <ResultExplorer />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="datahub/*"
        element={
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <Datahub />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="operations-overview/*"
        element={
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <OperationsOverview />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route
        path="migrate-data/*"
        element={
          <React.Suspense fallback={<Loading />}>
            <RequireAuth>
              <UploadData />
            </RequireAuth>
          </React.Suspense>
        }
      />
      <Route path="/error" element={<ErrorComponent eventId={null} />} />
    </Routes>
  );
};
