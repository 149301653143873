import React from 'react';

import { match } from '@utils/match';
import { once } from '@utils/once';
import { disposables } from '@utils/disposables';

function useIsInitialRender() {
  const initial = React.useRef(true);

  React.useEffect(() => {
    initial.current = false;
  }, []);

  return { isInitial: initial.current };
}

function useIsMounted() {
  const mounted = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted;
}

const useIsoMorphicEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

function addClasses(node: HTMLElement, ...classes: string[]) {
  node && classes.length > 0 && node.classList.add(...classes);
}

function removeClasses(node: HTMLElement, ...classes: string[]) {
  node && classes.length > 0 && node.classList.remove(...classes);
}

export enum Reason {
  Finished = 'finished',
  Cancelled = 'cancelled',
}

function waitForTransition(node: HTMLElement, done: (reason: Reason) => void) {
  const d = disposables();

  if (!node) return d.dispose;

  // Safari returns a comma separated list of values, so let's sort them and take the highest value.
  const { transitionDuration, transitionDelay } = getComputedStyle(node);

  const [durationMs, delaysMs] = [transitionDuration, transitionDelay].map((value) => {
    const [resolvedValue = 0] = value
      .split(',')
      // Remove falseys we can't work with
      .filter(Boolean)
      // Values are returned as `0.3s` or `75ms`
      .map((v) => (v.includes('ms') ? parseFloat(v) : parseFloat(v) * 1000))
      .sort((a, z) => z - a);

    return resolvedValue;
  });

  // Waiting for the transition to end. We could use the `transitionend` event, however when no
  // actual transition/duration is defined then the `transitionend` event is not fired.
  //
  // TODO: Downside is, when you slow down transitions via devtools this timeout is still using the
  // full 100% speed instead of the 25% or 10%.
  if (durationMs !== 0) {
    d.setTimeout(() => {
      done(Reason.Finished);
    }, durationMs + delaysMs);
  } else {
    // No transition is happening, so we should cleanup already. Otherwise we have to wait until we
    // get disposed.
    done(Reason.Finished);
  }

  // If we get disposed before the timeout runs we should cleanup anyway
  d.add(() => done(Reason.Cancelled));

  return d.dispose;
}

export function transition(
  node: HTMLElement,
  base: string[],
  from: string[],
  to: string[],
  done?: (reason: Reason) => void,
) {
  const d = disposables();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const _done = done !== undefined ? once(done) : () => {};

  addClasses(node, ...base, ...from);

  d.nextFrame(() => {
    removeClasses(node, ...from);
    addClasses(node, ...to);

    d.add(
      waitForTransition(node, (reason) => {
        removeClasses(node, ...to, ...base);
        return _done(reason);
      }),
    );
  });

  // Once we get disposed, we should ensure that we cleanup after ourselves. In case of an unmount,
  // the node itself will be nullified and will be a no-op. In case of a full transition the classes
  // are already removed which is also a no-op. However if you go from enter -> leave mid-transition
  // then we have some leftovers that should be cleaned.
  d.add(() => removeClasses(node, ...base, ...from, ...to));

  // When we get disposed early, than we should also call the done method but switch the reason.
  d.add(() => _done(Reason.Cancelled));

  return d.dispose;
}

type ID = string;

function useSplitClasses(classes = '') {
  return React.useMemo(() => classes.split(' ').filter((className) => className.trim().length > 1), [classes]);
}

type TransitionContextValues = {
  show: boolean;
  appear: boolean;
} | null;
const TransitionContext = React.createContext<TransitionContextValues>(null);

enum TreeStates {
  Visible = 'visible',
  Hidden = 'hidden',
}

type TransitionClasses = Partial<{
  enter: string;
  enterFrom: string;
  enterTo: string;
  leave: string;
  leaveFrom: string;
  leaveTo: string;
}>;

type HTMlTags = keyof JSX.IntrinsicElements;
type HTMLTagProps<TTag extends HTMlTags> = JSX.IntrinsicElements[TTag];

type AsShortcut<TTag extends HTMlTags> = {
  children?: React.ReactNode;
  as?: TTag;
} & Omit<HTMLTagProps<TTag>, 'ref'>;

type AsRenderPropFunction = {
  children: (ref: React.MutableRefObject<unknown>) => JSX.Element;
};

type BaseConfig = Partial<{ appear: boolean }>;

type TransitionChildProps<TTag extends HTMlTags> = BaseConfig &
  (AsShortcut<TTag> | AsRenderPropFunction) &
  TransitionClasses;

function useTransitionContext() {
  const context = React.useContext(TransitionContext);

  if (context === null) {
    throw new Error('A <Transition.Child /> is used but it is missing a parent <Transition />.');
  }

  return context;
}

function useParentNesting() {
  const context = React.useContext(NestingContext);

  if (context === null) {
    throw new Error('A <Transition.Child /> is used but it is missing a parent <Transition />.');
  }

  return context;
}

type NestingContextValues = {
  children: React.MutableRefObject<ID[]>;
  register: (id: ID) => () => void;
  unregister: (id: ID) => void;
};

const NestingContext = React.createContext<NestingContextValues | null>(null);

function useNesting(done?: () => void) {
  const transitionableChildren = React.useRef<ID[]>([]);
  const mounted = useIsMounted();

  const unregister = React.useCallback(
    (childId: ID) => {
      const idx = transitionableChildren.current.indexOf(childId);

      if (idx === -1) return;

      transitionableChildren.current.splice(idx, 1);

      if (transitionableChildren.current.length <= 0 && mounted.current) {
        done?.();
      }
    },
    [done, mounted, transitionableChildren],
  );

  const register = React.useCallback(
    (childId: ID) => {
      transitionableChildren.current.push(childId);
      return () => unregister(childId);
    },
    [transitionableChildren, unregister],
  );

  return React.useMemo(
    () => ({
      children: transitionableChildren,
      register,
      unregister,
    }),
    [register, unregister, transitionableChildren],
  );
}

function TransitionChild<TTag extends HTMlTags = 'div'>(props: TransitionChildProps<TTag>) {
  const { children, enter, enterFrom, enterTo, leave, leaveFrom, leaveTo, ...rest } = props;
  const container = React.useRef<HTMLElement | null>(null);
  const [state, setState] = React.useState(TreeStates.Visible);

  const { show, appear } = useTransitionContext();
  const { register, unregister } = useParentNesting();

  const { isInitial } = useIsInitialRender();
  const id = React.useId();

  const isTransitioning = React.useRef(false);

  const nesting = useNesting(
    React.useCallback(() => {
      // When all children have been unmounted we can only hide ourselves if and only if we are not
      // transitioning ourselves. Otherwise we would unmount before the transitions are finished.
      if (!isTransitioning.current) {
        setState(TreeStates.Hidden);
        unregister(id);
      }
    }, [id, unregister, isTransitioning]),
  );

  useIsoMorphicEffect(() => register(id), [register, id]);

  const enterClasses = useSplitClasses(enter);
  const enterFromClasses = useSplitClasses(enterFrom);
  const enterToClasses = useSplitClasses(enterTo);

  const leaveClasses = useSplitClasses(leave);
  const leaveFromClasses = useSplitClasses(leaveFrom);
  const leaveToClasses = useSplitClasses(leaveTo);

  React.useEffect(() => {
    if (state === TreeStates.Visible && container.current === null) {
      throw new Error('Did you forget to passthrough the `ref` to the actual DOM node?');
    }
  }, [container, state]);

  useIsoMorphicEffect(() => {
    const node = container.current;

    if (!node) return;

    // Skipping initial transition
    if (isInitial && !appear) return;

    isTransitioning.current = true;

    return show
      ? transition(node, enterClasses, enterFromClasses, enterToClasses, () => {
          isTransitioning.current = false;
        })
      : transition(node, leaveClasses, leaveFromClasses, leaveToClasses, (reason) => {
          isTransitioning.current = false;

          if (reason !== Reason.Finished) return;

          // When we don't have children anymore we can safely unregister from the parent and hide
          // ourselves.
          if (nesting.children.current.length <= 0) {
            setState(TreeStates.Hidden);
            unregister(id);
          }
        });
  }, [
    id,
    isTransitioning,
    unregister,
    nesting,
    container,
    isInitial,
    appear,
    show,
    enterClasses,
    enterFromClasses,
    enterToClasses,
    leaveClasses,
    leaveFromClasses,
    leaveToClasses,
  ]);

  // Unmount the whole tree
  if (state === TreeStates.Hidden) return null;

  if (typeof children === 'function') {
    return (
      <NestingContext.Provider value={nesting}>
        {(children as AsRenderPropFunction['children'])(container)}
      </NestingContext.Provider>
    );
  }

  const { as: Component = 'div', ...passthroughProps } = rest as AsShortcut<TTag>;
  return (
    <NestingContext.Provider value={nesting}>
      {/* @ts-expect-error Expression produces a union type that is too complex to represent. */}
      <Component {...passthroughProps} ref={container}>
        {children}
      </Component>
    </NestingContext.Provider>
  );
}

export function Transition<TTag extends HTMlTags = 'div'>(
  props: TransitionChildProps<TTag> & { show: boolean; appear?: boolean },
) {
  const { show, appear = false, ...rest } = props;

  if (![true, false].includes(show)) {
    throw new Error('A <Transition /> is used but it is missing a `show={true | false}` prop.');
  }

  const [state, setState] = React.useState(show ? TreeStates.Visible : TreeStates.Hidden);

  const nestingBag = useNesting(
    React.useCallback(() => {
      setState(TreeStates.Hidden);
    }, []),
  );

  const { isInitial } = useIsInitialRender();
  const transitionBag = React.useMemo<TransitionContextValues>(
    () => ({ show, appear: appear || !isInitial }),
    [show, appear, isInitial],
  );

  React.useEffect(() => {
    if (show) {
      setState(TreeStates.Visible);
    } else if (nestingBag.children.current.length <= 0) {
      setState(TreeStates.Hidden);
    }
  }, [show, nestingBag]);

  return (
    <NestingContext.Provider value={nestingBag}>
      <TransitionContext.Provider value={transitionBag}>
        {match(state, {
          [TreeStates.Visible]: () => <TransitionChild {...rest} />,
          [TreeStates.Hidden]: null,
        })}
      </TransitionContext.Provider>
    </NestingContext.Provider>
  );
}

Transition.Child = TransitionChild;
