import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom';

import { Button } from '@components/index';

interface ErrorComponentProps {
  eventId: string | null;
}

export const ErrorComponent = ({ eventId }: ErrorComponentProps): JSX.Element => {
  const location = useLocation();
  const componentStack = location.search;

  return (
    <div className="h-screen bg-gray-200 p-6">
      <div className="mx-auto max-w-2xl overflow-hidden rounded-lg bg-white shadow">
        <div className="py-5 px-4 sm:p-6">
          <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
            <div className="flex">
              <div className="shrink-0">
                <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm leading-5 text-yellow-700">
                  Sorry there was a problem loading this page.&nbsp;&nbsp;&nbsp;
                  <a
                    onClick={() => {
                      window.location.reload();
                    }}
                    className="cursor-pointer font-medium text-yellow-700 underline transition duration-150 ease-in-out hover:text-yellow-600"
                  >
                    Reload this page.
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="py-6">
            <details>
              <summary>Click for error details</summary>
              <pre>{componentStack}</pre>
            </details>
          </div>

          <Button
            type="button"
            color="indigo"
            disabled={eventId === null}
            onClick={() => {
              if (eventId) {
                Sentry.showReportDialog({ eventId: eventId });
              }
            }}
          >
            Report feedback
          </Button>
        </div>
      </div>
    </div>
  );
};
