import { createRoot } from 'react-dom/client';

import './index.css';

import App from './app/App';

const render = () => {
  const container = document.getElementById('root');
  const root = createRoot(container as HTMLElement);
  root.render(<App />);
};

render();
