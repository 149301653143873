import { useEffect, useRef, forwardRef, Fragment } from 'react';

interface Props {
  indeterminate?: boolean;
  disabled?: boolean;
  name: string;
}

const useCombinedRefs = (...refs: React.Ref<HTMLInputElement>[]): React.MutableRefObject<HTMLInputElement> => {
  const targetRef = useRef<HTMLInputElement>();

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current as HTMLInputElement);
      } else {
        (ref.current as HTMLInputElement) = targetRef.current as HTMLInputElement;
      }
    });
  }, [refs]);

  return targetRef as React.MutableRefObject<HTMLInputElement>;
};

export const IndeterminateCheckbox = forwardRef<HTMLInputElement, Props>(
  ({ indeterminate, disabled = false, ...rest }, ref: React.Ref<HTMLInputElement>) => {
    const defaultRef = useRef(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);

    useEffect(() => {
      if (combinedRef?.current) {
        combinedRef.current.indeterminate = indeterminate ?? false;
      }
    }, [combinedRef, indeterminate]);

    return (
      <Fragment>
        <input
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          type="checkbox"
          disabled={disabled}
          ref={combinedRef}
          {...rest}
        />
      </Fragment>
    );
  },
);
