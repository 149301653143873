import { useMemo } from 'react';
import classnames from 'classnames';

type ButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'> & {
  color: 'white' | 'red' | 'indigo' | 'green';
  disabled?: boolean;
  isFullWidth?: boolean;
  onClick?: () => void;
  className?: string;
};

export const Button: React.FC<ButtonProps> = ({
  type,
  color,
  isFullWidth = false,
  disabled = false,
  onClick,
  className,
  children,
  ...rest
}): JSX.Element => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick === undefined) {
      return;
    }

    e.preventDefault();
    onClick();
  };

  const whiteClass = classnames(
    'inline-flex justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm sm:mt-0',
    className ?? '',
    {
      'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2': !disabled,
    },
    { 'cursor-not-allowed opacity-50': disabled },
    { 'w-auto': !isFullWidth },
    { 'w-full': isFullWidth },
  );

  const indigoClass = classnames(
    'inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm sm:mt-0',
    className ?? '',
    {
      'hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2': !disabled,
    },
    { 'cursor-not-allowed opacity-50': disabled },
    { 'w-auto': !isFullWidth },
    { 'w-full': isFullWidth },
  );

  const redClass = classnames(
    'inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm sm:mt-0',
    {
      'hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2': !disabled,
    },
    { 'cursor-not-allowed opacity-50': disabled },
    { 'w-auto': !isFullWidth },
    { 'w-full': isFullWidth },
  );

  const greenClass = classnames(
    'inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm sm:mt-0',
    {
      'hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2': !disabled,
    },
    { 'cursor-not-allowed opacity-50': disabled },
    { 'w-auto': !isFullWidth },
    { 'w-full': isFullWidth },
  );

  const btnClass = useMemo(() => {
    if (color === 'white') {
      return whiteClass;
    }

    if (color === 'indigo') {
      return indigoClass;
    }

    if (color === 'red') {
      return redClass;
    }

    if (color === 'green') {
      return greenClass;
    }

    return whiteClass;
  }, [color, disabled, className]);

  return (
    <button type={type} onClick={handleClick} disabled={disabled} className={btnClass} {...rest}>
      {children}
    </button>
  );
};
