import { Navigate } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';

import { StudyManagerRoutes } from '@utils/routes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RequireAuth: React.FC<any> = ({ children }) => {
  const { keycloak } = useKeycloak();
  return keycloak?.authenticated ? children : <Navigate to={StudyManagerRoutes.Users.Login} replace />;
};
