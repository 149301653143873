import cx from 'classnames';

type LoadingProps = React.HTMLAttributes<HTMLDivElement> & {
  text?: string;
};

export const Loading: React.FC<LoadingProps> = ({ text = 'Loading Data ...', className, ...rest }) => (
  <div className={cx('flex flex-1 flex-col items-center justify-center bg-gray-50', className)} {...rest}>
    <div className="w-full max-w-md text-center">
      <img className="inline-block" width="100px" src="/mouse.gif" alt="Loading animation" />
      <h3 className="py-4">{text}</h3>
    </div>
  </div>
);
