import Keycloak, { KeycloakInitOptions } from 'keycloak-js';
import { config } from 'src/config';

export interface AuthToken {
  /**
   * The base64 encoded token that can be sent in the Authorization header in
   * requests to services.
   */
  token?: string;
  /**
   * The base64 encoded refresh token that can be used to retrieve a new token.
   */
  refreshToken?: string;
  /**
   * The base64 encoded ID token.
   */
  idToken?: string;
}

export const keycloakInitOptions: KeycloakInitOptions = {
  responseMode: 'query',
  flow: 'standard',
  checkLoginIframe: false,
};

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
  url: config.keycloak.url,
  realm: config.keycloak.realm,
  clientId: config.keycloak.clientId,
});

export function shouldAllowRTNPlotsAccess(): boolean {
  return keycloak.hasRealmRole('RtnReaders');
}

export function shouldAnonymiseData(): boolean {
  return !keycloak.hasRealmRole('InsiteReaders');
}

export default keycloak;
