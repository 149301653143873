import { useNavigate } from 'react-router-dom';

export const StudyManagerRoutes = {
  Root: '/study-manager',
  Mice: {
    List: '/study-manager/mice',
    Edit: '/study-manager/mice/:mouseId/edit',
    AddToStudyGroup: '/study-manager/mice/add-to-study-group',
    EditMouseWeights: '/study-manager/mice/edit-weights',
    TakeDownMice: '/study-manager/mice/take-down',
    PrintLabels: '/study-manager/mice/print-labels',
    RegisterTissues: '/study-manager/mice/register-tissues',
  },
  Therapies: {
    List: '/study-manager/therapies',
    Add: '/study-manager/therapies/add',
    Edit: '/study-manager/therapies/:drugPlanId/edit',
    Delete: '/study-manager/therapies/:drugPlanId/delete',
    AddToStudyGroup: '/study-manager/therapies/:drugPlanId/add-to-study-group',
  },
  MouseMeasurement: {
    Add: '/study-manager/mice/:mouseId/measurement/add',
    Edit: '/study-manager/mice/:mouseId/measurement/:measurementId/edit',
    Delete: '/study-manager/mice/:mouseId/measurement/:measurementId/delete',
  },
  Studies: {
    List: '/study-manager/studies',
    Add: '/study-manager/studies/add',
    Edit: '/study-manager/studies/:studyId/edit',
    Delete: '/study-manager/studies/:studyId/delete',
    EditStudyGroups: '/study-manager/studies/:studyId/study-groups/edit',
    GenerateProcessingOrder: '/study-manager/studies/:studyId/generate-processing-order',
  },
  TreatmentRecords: {
    Edit: '/study-manager/treatment-records/study/:studyId',
  },
  StudyGroup: {
    Add: '/study-manager/studies/:studyId/study-groups/add',
    Edit: '/study-manager/studies/:studyId/study-groups/:studyGroupId/edit',
    Delete: '/study-manager/studies/:studyId/study-groups/:studyGroupId/delete',
    EditDrugs: '/study-manager/studies/:studyId/study-groups/:studyGroupId/drugs/edit',
  },
  DosingPlan: {
    Edit: '/study-manager/dosing-plan/study/:studyId/study-groups/:studyGroupId',
    AddDosingModification: '/study-manager/dosing-plan/study/:studyId/study-groups/:studyGroupId/dosing-modification',
    AddDosingHoliday: '/study-manager/dosing-plan/study/:studyId/study-groups/:studyGroupId/dosing-holiday',
    DeleteTherapyModification:
      '/study-manager/dosing-plan/study/:studyId/study-groups/:studyGroupId/delete-therapy-modification',
  },
  Drugs: {
    List: '/study-manager/drugs',
    Add: '/study-manager/drugs/add',
    Edit: '/study-manager/drugs/:drugId/edit',
    Delete: '/study-manager/drugs/:drugId/delete',
  },
  Vehicles: {
    List: '/study-manager/vehicles',
    Add: '/study-manager/vehicles/add',
    Edit: '/study-manager/vehicles/:vehicleId/edit',
    Delete: '/study-manager/vehicles/:vehicleId/delete',
  },
  VirusPools: {
    List: '/study-manager/virus-pools',
  },
  BulkUpdate: '/study-manager/bulk-update',
  Users: {
    Login: '/users/login',
  },
};

export const OperationsOverviewRoutes = {
  Root: '/operations-overview',
  Mice: '/operations-overview/mice',
  Timeline: {
    Studies: '/operations-overview/timeline/studies',
    Calendar: '/operations-overview/timeline-calendar/studies',
  },
};

export const DataPortalRoutes = {
  Root: '/data-portal',
  Plots: {
    Root: '/data-portal/plots',
    Qc: '/data-portal/plots/qc',
    DemuxSeqRun: '/data-portal/plots/demux-seqrun',
    TumorResult: '/data-portal/plots/tumor-result',
  },
  Patients: {
    Root: '/data-portal/patients',
    CohortSummary: '/data-portal/patients/cohort-summary',
    ClinicalData: '/data-portal/patients/clinical-data',
    MolecularData: '/data-portal/patients/molecular-data',
  },
};

export const LabPortalRoutes = {
  Root: '/lab-portal',
  TapeStation: '/lab-portal/tape-station',
  SeqRunQc: '/lab-portal/seqrun-qc',
};

export const ResultExplorerRoutes = {
  Root: '/result-explorer',
  RTN: '/result-explorer/rtn',
  Pathways: '/result-explorer/pathways',
  Strata: '/result-explorer/strata',
  Pdx: {
    Root: '/result-explorer/pdx',
    TvMean: '/result-explorer/pdx/tv-mean',
    TvIndiv: '/result-explorer/pdx/tv-indiv',
    BwMean: '/result-explorer/pdx/bw-mean',
    BwIndiv: '/result-explorer/pdx/bw-indiv',
    TvWaterfallMean: '/result-explorer/pdx/tv-waterfall-mean',
    TvWaterfallIndividual: '/result-explorer/pdx/tv-waterfall-indiv',
  },
};

export const DatahubRoutes = {
  Root: 'https://notebooks.d2g-insite.com:8888',
};

export const MigrateDataRoutes = {
  Root: '/migrate-data',
  ImportHusbandry: '/migrate-data/import-husbandry',
  ExportBenchling: '/migrate-data/export-benchling',
  ExportDatahub: '/migrate-data/export-datahub',
  ImportPDx: '/migrate-data/import-pdx',
  ImportCellLine: '/migrate-data/import-cell-line',
};

interface ModalHelpersInstance {
  addStudyModal: {
    open: () => void;
    path: string;
  };
  editStudyModal: {
    open: (studyId: number) => void;
    path: string;
  };
  deleteStudyModal: {
    open: (studyId: number) => void;
    path: string;
  };
  modalClose: () => void;
  addStudyGroupModal: {
    open: (studyId: number) => void;
    path: string;
  };
  editStudyGroupModal: {
    open: (studyId: number, studyGroupId: number) => void;
    path: string;
  };
  deleteStudyGroupModal: {
    open: (studyId: number, studyGroupId: number) => void;
    path: string;
  };
  dosingPlanPage: {
    open: (studyId: number, studyGroupId: number) => void;
    path: string;
  };
  addDosingModificationModal: {
    open: (studyId: number, studyGroupId: number) => void;
    path: string;
  };
  addDosingHolidayModal: {
    open: (studyId: number, studyGroupId: number) => void;
    path: string;
  };
  deleteTherapyModificationModal: {
    open: (studyId: number, studyGroupId: number) => void;
    path: string;
  };
  editStudyGroupDrugsPage: {
    open: (studyId: number, studyGroupId: number) => void;
    path: string;
  };
  studyTreatmentRecordsPage: {
    open: (studyId: number) => void;
    path: string;
  };
  studiesPage: {
    open: (studyName: string) => void;
    path: string;
  };
  micePage: {
    open: (studyName: string, studyGroupName?: string) => void;
    path: string;
  };
  drugsPage: {
    open: (drug: string) => void;
    path: string;
  };
  therapiesPage: {
    open: (drug: string) => void;
    path: string;
  };
  vehiclesPage: {
    open: (vehicle: string) => void;
    path: string;
  };
  addDrugModal: {
    open: () => void;
    path: string;
  };
  editDrugModal: {
    open: (drugId: number) => void;
    path: string;
  };
  editMouseModal: {
    open: (mouseId: number) => void;
    path: string;
  };
  deleteDrugModal: {
    open: (drugId: number) => void;
    path: string;
  };
  addVehicleModal: {
    open: () => void;
    path: string;
  };
  editVehicleModal: {
    open: (drugId: number) => void;
    path: string;
  };
  deleteVehicleModal: {
    open: (drugId: number) => void;
    path: string;
  };
  addDrugPlanModal: {
    open: () => void;
    path: string;
  };
  editDrugPlanModal: {
    open: (drugPlanId: number) => void;
    path: string;
  };
  deleteDrugPlanModal: {
    open: (drugPlanId: number) => void;
    path: string;
  };
  addDrugPlanToStudyGroupModal: {
    open: (drugPlanId: number) => void;
    path: string;
  };
  addMiceToStudyGroupModal: {
    open: () => void;
    path: string;
  };
  addMouseMeasurementModal: {
    open: (mouseId: number) => void;
    path: string;
  };
  editMouseMeasurementModal: {
    open: (mouseId: number, measurementId: number) => void;
    path: string;
  };
  deleteMouseMeasurementModal: {
    open: (mouseId: number, measurementId: number) => void;
    path: string;
  };
  editMouseWeightsModal: {
    open: () => void;
    path: string;
  };
  editStudyGroupsModal: {
    open: (studyId: number) => void;
    path: string;
  };
  generateProcessingOrderModal: {
    open: (studyId: number) => void;
    path: string;
  };
  takeDownMiceModal: {
    open: () => void;
    path: string;
  };
  printLabelsModal: {
    open: () => void;
    path: string;
  };
  registerTissuesModal: {
    open: () => void;
    path: string;
  };
  dataPortalPage: {
    open: (studies: string[], options?: { newWindow: boolean }) => void;
    path: string;
  };
  patientDetailsPage: {
    open: () => void;
    path: string;
  };
  virusPoolsPage: {
    open: (virusPoolName: string) => void;
    path: string;
  };
  seqRunQcPage: {
    open: (seqRunId: string, seqRunName: string) => void;
    path: string;
  };
  operationalOverviewTimelinePage: {
    open: () => void;
    path: string;
  };
}

export function useModalHelpers(): ModalHelpersInstance {
  const navigate = useNavigate();
  const path = '/';

  const addStudyModal = {
    open: (): void => navigate(StudyManagerRoutes.Studies.Add),
    path: StudyManagerRoutes.Studies.Add,
  };

  const editStudyModal = {
    open: (studyId: number): void => navigate(StudyManagerRoutes.Studies.Edit.replace(':studyId', studyId.toString())),
    path: StudyManagerRoutes.Studies.Edit,
  };

  const deleteStudyModal = {
    open: (studyId: number): void =>
      navigate(StudyManagerRoutes.Studies.Delete.replace(':studyId', studyId.toString())),
    path: StudyManagerRoutes.Studies.Delete,
  };

  const modalClose = () => navigate(path);

  const addStudyGroupModal = {
    open: (studyId: number): void =>
      navigate(StudyManagerRoutes.StudyGroup.Add.replace(':studyId', studyId.toString())),
    path: StudyManagerRoutes.StudyGroup.Add,
  };

  const editStudyGroupModal = {
    open: (studyId: number, studyGroupId: number): void =>
      navigate(
        StudyManagerRoutes.StudyGroup.Edit.replace(':studyId', studyId.toString()).replace(
          ':studyGroupId',
          studyGroupId.toString(),
        ),
      ),
    path: StudyManagerRoutes.StudyGroup.Edit,
  };

  const deleteStudyGroupModal = {
    open: (studyId: number, studyGroupId: number): void =>
      navigate(
        StudyManagerRoutes.StudyGroup.Delete.replace(':studyId', studyId.toString()).replace(
          ':studyGroupId',
          studyGroupId.toString(),
        ),
      ),
    path: StudyManagerRoutes.StudyGroup.Delete,
  };

  const dosingPlanPage = {
    open: (studyId: number, studyGroupId: number) =>
      navigate(
        StudyManagerRoutes.DosingPlan.Edit.replace(':studyId', studyId.toString()).replace(
          ':studyGroupId',
          studyGroupId.toString(),
        ),
      ),
    path: StudyManagerRoutes.DosingPlan.Edit,
  };

  const addDosingModificationModal = {
    open: (studyId: number, studyGroupId: number) =>
      navigate(
        StudyManagerRoutes.DosingPlan.AddDosingModification.replace(':studyId', studyId.toString()).replace(
          ':studyGroupId',
          studyGroupId.toString(),
        ),
      ),
    path: StudyManagerRoutes.DosingPlan.AddDosingModification,
  };

  const addDosingHolidayModal = {
    open: (studyId: number, studyGroupId: number) =>
      navigate(
        StudyManagerRoutes.DosingPlan.AddDosingHoliday.replace(':studyId', studyId.toString()).replace(
          ':studyGroupId',
          studyGroupId.toString(),
        ),
      ),
    path: StudyManagerRoutes.DosingPlan.AddDosingHoliday,
  };

  const deleteTherapyModificationModal = {
    open: (studyId: number, studyGroupId: number) =>
      navigate(
        StudyManagerRoutes.DosingPlan.DeleteTherapyModification.replace(':studyId', studyId.toString()).replace(
          ':studyGroupId',
          studyGroupId.toString(),
        ),
      ),
    path: StudyManagerRoutes.DosingPlan.DeleteTherapyModification,
  };

  const editStudyGroupDrugsPage = {
    open: (studyId: number, studyGroupId: number) =>
      navigate(
        StudyManagerRoutes.StudyGroup.EditDrugs.replace(':studyId', studyId.toString()).replace(
          ':studyGroupId',
          studyGroupId.toString(),
        ),
      ),
    path: StudyManagerRoutes.StudyGroup.EditDrugs,
  };

  const micePage = {
    open: (studyName: string, studyGroupName?: string): void => {
      let miceUrl = `${StudyManagerRoutes.Mice.List}?study=${studyName}`;

      if (studyGroupName) {
        miceUrl = miceUrl + `&studyGroup=${studyGroupName}`;
      }

      navigate(miceUrl);
    },
    path: StudyManagerRoutes.Mice.List,
  };

  const studiesPage = {
    open: (studyName: string): void => navigate(`${StudyManagerRoutes.Studies.List}?study=${studyName}`),
    path: StudyManagerRoutes.Studies.List,
  };

  const drugsPage = {
    open: (drug: string): void => navigate(`${StudyManagerRoutes.Drugs.List}?drug=${drug}`),
    path: StudyManagerRoutes.Drugs.List,
  };

  const therapiesPage = {
    open: (drug: string): void => navigate(`${StudyManagerRoutes.Therapies.List}?drug=${drug}`),
    path: StudyManagerRoutes.Therapies.List,
  };

  const vehiclesPage = {
    open: (vehicle: string): void => navigate(`${StudyManagerRoutes.Vehicles.List}?vehicle=${vehicle}`),
    path: StudyManagerRoutes.Vehicles.List,
  };

  const addDrugModal = {
    open: (): void => navigate(StudyManagerRoutes.Drugs.Add),
    path: StudyManagerRoutes.Drugs.Add,
  };

  const editDrugModal = {
    open: (drugId: number): void => navigate(StudyManagerRoutes.Drugs.Edit.replace(':drugId', drugId.toString())),
    path: StudyManagerRoutes.Drugs.Edit,
  };

  const deleteDrugModal = {
    open: (drugId: number): void => navigate(StudyManagerRoutes.Drugs.Delete.replace(':drugId', drugId.toString())),
    path: StudyManagerRoutes.Drugs.Delete,
  };

  const addDrugPlanModal = {
    open: (): void => navigate(StudyManagerRoutes.Therapies.Add),
    path: StudyManagerRoutes.Therapies.Add,
  };

  const editDrugPlanModal = {
    open: (drugPlanId: number): void =>
      navigate(StudyManagerRoutes.Therapies.Edit.replace(':drugPlanId', drugPlanId.toString())),
    path: StudyManagerRoutes.Therapies.Edit,
  };

  const deleteDrugPlanModal = {
    open: (drugPlanId: number): void =>
      navigate(StudyManagerRoutes.Therapies.Delete.replace(':drugPlanId', drugPlanId.toString())),
    path: StudyManagerRoutes.Therapies.Delete,
  };

  const addVehicleModal = {
    open: (): void => navigate(StudyManagerRoutes.Vehicles.Add),
    path: StudyManagerRoutes.Vehicles.Add,
  };

  const editVehicleModal = {
    open: (vehicleId: number): void =>
      navigate(StudyManagerRoutes.Vehicles.Edit.replace(':vehicleId', vehicleId.toString())),
    path: StudyManagerRoutes.Vehicles.Edit,
  };

  const deleteVehicleModal = {
    open: (vehicleId: number): void =>
      navigate(StudyManagerRoutes.Vehicles.Delete.replace(':vehicleId', vehicleId.toString())),
    path: StudyManagerRoutes.Vehicles.Delete,
  };

  const editMouseModal = {
    open: (mouseId: number): void => navigate(StudyManagerRoutes.Mice.Edit.replace(':mouseId', mouseId.toString())),
    path: StudyManagerRoutes.Mice.Edit,
  };

  const addDrugPlanToStudyGroupModal = {
    open: (drugPlanId: number): void =>
      navigate(StudyManagerRoutes.Therapies.AddToStudyGroup.replace(':drugPlanId', drugPlanId.toString())),
    path: StudyManagerRoutes.Therapies.AddToStudyGroup,
  };

  const addMiceToStudyGroupModal = {
    open: (): void => navigate(StudyManagerRoutes.Mice.AddToStudyGroup),
    path: StudyManagerRoutes.Mice.AddToStudyGroup,
  };

  const addMouseMeasurementModal = {
    open: (mouseId: number): void =>
      navigate(StudyManagerRoutes.MouseMeasurement.Add.replace(':mouseId', mouseId.toString())),
    path: StudyManagerRoutes.MouseMeasurement.Add,
  };

  const editMouseMeasurementModal = {
    open: (mouseId: number, measurementId: number): void =>
      navigate(
        StudyManagerRoutes.MouseMeasurement.Edit.replace(':mouseId', mouseId.toString()).replace(
          ':measurementId',
          measurementId.toString(),
        ),
      ),
    path: StudyManagerRoutes.MouseMeasurement.Edit,
  };

  const deleteMouseMeasurementModal = {
    open: (mouseId: number, measurementId: number): void =>
      navigate(
        StudyManagerRoutes.MouseMeasurement.Delete.replace(':mouseId', mouseId.toString()).replace(
          ':measurementId',
          measurementId.toString(),
        ),
      ),
    path: StudyManagerRoutes.MouseMeasurement.Delete,
  };

  const editMouseWeightsModal = {
    open: (): void => {
      navigate(StudyManagerRoutes.Mice.EditMouseWeights);
    },
    path: StudyManagerRoutes.Mice.EditMouseWeights,
  };

  const editStudyGroupsModal = {
    open: (studyId: number): void =>
      navigate(StudyManagerRoutes.Studies.EditStudyGroups.replace(':studyId', studyId.toString())),
    path: StudyManagerRoutes.Studies.EditStudyGroups,
  };

  const generateProcessingOrderModal = {
    open: (studyId: number): void =>
      navigate(StudyManagerRoutes.Studies.GenerateProcessingOrder.replace(':studyId', studyId.toString())),
    path: StudyManagerRoutes.Studies.GenerateProcessingOrder,
  };

  const takeDownMiceModal = {
    open: (): void => navigate(StudyManagerRoutes.Mice.TakeDownMice),
    path: StudyManagerRoutes.Mice.TakeDownMice,
  };

  const printLabelsModal = {
    open: (): void => navigate(StudyManagerRoutes.Mice.PrintLabels),
    path: StudyManagerRoutes.Mice.PrintLabels,
  };

  const registerTissuesModal = {
    open: (): void => navigate(StudyManagerRoutes.Mice.RegisterTissues),
    path: StudyManagerRoutes.Mice.RegisterTissues,
  };

  const studyTreatmentRecordsPage = {
    open: (studyId: number): void =>
      navigate(StudyManagerRoutes.TreatmentRecords.Edit.replace(':studyId', studyId.toString())),
    path: StudyManagerRoutes.TreatmentRecords.Edit,
  };

  const dataPortalPage = {
    open: (studies: string[], options?: { newWindow: boolean }): void => {
      const reUrl = `${DataPortalRoutes.Root}?studies=${JSON.stringify(studies)}`;

      // if (studyGroupName) {
      //   miceUrl = miceUrl + `&studyGroup=${studyGroupName}`;
      // }

      if (options?.newWindow === true) {
        window.open(reUrl, '_blank');
      } else {
        navigate(reUrl);
      }
    },
    path: DataPortalRoutes.Root,
  };

  const patientDetailsPage = {
    open: (): void => navigate(DataPortalRoutes.Patients.MolecularData),
    path: DataPortalRoutes.Patients.MolecularData,
  };

  const virusPoolsPage = {
    open: (virusPoolName: string): void => {
      const virusPoolsUrl = `${StudyManagerRoutes.VirusPools.List}?virus_pool=${virusPoolName}`;

      navigate(virusPoolsUrl);
    },
    path: StudyManagerRoutes.VirusPools.List,
  };

  const seqRunQcPage = {
    open: (seqRunId: string, seqRunName: string): void => {
      navigate(`${LabPortalRoutes.SeqRunQc}/${seqRunId}/${seqRunName}`);
    },
    path: LabPortalRoutes.SeqRunQc,
  };

  const operationalOverviewTimelinePage = {
    open: (): void => {
      navigate(OperationsOverviewRoutes.Timeline.Calendar);
    },
    path: OperationsOverviewRoutes.Timeline.Calendar,
  };

  return {
    addStudyModal,
    editStudyModal,
    deleteStudyModal,
    modalClose,
    addStudyGroupModal,
    editStudyGroupModal,
    deleteStudyGroupModal,
    editStudyGroupDrugsPage,
    dosingPlanPage,
    addDosingModificationModal,
    addDosingHolidayModal,
    deleteTherapyModificationModal,
    studyTreatmentRecordsPage,
    studiesPage,
    micePage,
    drugsPage,
    therapiesPage,
    vehiclesPage,
    addDrugModal,
    editDrugModal,
    deleteDrugModal,
    addVehicleModal,
    editVehicleModal,
    deleteVehicleModal,
    addDrugPlanModal,
    editDrugPlanModal,
    deleteDrugPlanModal,
    addDrugPlanToStudyGroupModal,
    editMouseModal,
    addMiceToStudyGroupModal,
    addMouseMeasurementModal,
    editMouseMeasurementModal,
    deleteMouseMeasurementModal,
    editMouseWeightsModal,
    editStudyGroupsModal,
    generateProcessingOrderModal,
    takeDownMiceModal,
    printLabelsModal,
    registerTissuesModal,
    dataPortalPage,
    patientDetailsPage,
    virusPoolsPage,
    seqRunQcPage,
    operationalOverviewTimelinePage,
  };
}
