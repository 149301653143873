import { forwardRef } from 'react';
import cx from 'classnames';
import type { RefCallBack, UseFormRegister, UseFormRegisterReturn } from 'react-hook-form';

export type Option = {
  value: string;
  label: string;
};

interface SimpleSelectInputProps {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: UseFormRegister<any>;
  disabled?: boolean;
  isInvalid?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Option[];
  selectedOption?: string;
}

export const SimpleSelectInput = forwardRef<HTMLSelectElement, SimpleSelectInputProps>(
  ({ id, register, disabled = false, isInvalid, onChange, options, selectedOption, ...rest }, ref) => {
    let formRef: RefCallBack | undefined = undefined;
    let registerProps: Partial<UseFormRegisterReturn<string>> = {};

    if (register) {
      const { ref: registerRef, ...registerRest } = register(id as string, { onChange });
      formRef = registerRef;
      registerProps = registerRest;
    }

    return (
      <fieldset disabled={disabled}>
        <select
          id={id}
          onChange={onChange}
          {...registerProps}
          defaultValue={selectedOption}
          className={cx('mt-1 block w-full max-w-lg pl-3 pr-10 py-2 text-base sm:text-sm rounded-md shadow-sm', {
            'border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50 focus:border-blue-300':
              !isInvalid,
            'border-red-300 placeholder-red-300 text-red-900 focus:outline-none focus:ring-2 focus:ring-red-200 focus:ring-opacity-50 focus:border-red-300':
              isInvalid,
            'opacity-50 cursor-not-allowed': disabled,
          })}
          ref={(e) => {
            if (formRef) {
              formRef(e);
            }
            if (typeof ref === 'function') {
              ref(e);
            } else if (ref) {
              ref.current = e;
            }
          }}
          {...rest}
        >
          {options.map((o, i) => (
            <option value={o.value} key={i}>
              {o.label}
            </option>
          ))}
        </select>
      </fieldset>
    );
  },
);
