import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { ModalProvider } from 'react-modal-hook';

import { ErrorComponent, Loading } from '@components/index';

import { AppRouter } from './AppRouter';
import { config } from 'src/config';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak, { keycloakInitOptions } from './keycloak';

let sentryLogger = undefined;

if (config.isDevMode === false && config.sentry.dsn) {
  const { dsn, environment, release } = config.sentry;
  Sentry.init({
    dsn,
    environment,
    release,

    initialScope: {
      tags: { component: 'frontend' },
    },

    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });

  // Sentry logger
  sentryLogger = {
    log: (message: string) => {
      Sentry.captureMessage(message);
    },
    warn: (message: string) => {
      Sentry.captureMessage(message);
    },
    error: (error: string) => {
      Sentry.captureException(error);
    },
  };
}

const queryClient = new QueryClient({
  logger: sentryLogger,
});

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={(error) => <ErrorComponent {...error} />}>
      <QueryClientProvider client={queryClient}>
        <ReactKeycloakProvider
          authClient={keycloak}
          initOptions={keycloakInitOptions}
          LoadingComponent={<Loading className="h-screen" text="Authenticating ..." />}
        >
          <ModalProvider>
            <AppRouter />
            <ReactQueryDevtools initialIsOpen={false} />
          </ModalProvider>
        </ReactKeycloakProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};

export default Sentry.withProfiler(App);
