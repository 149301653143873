import packageJson from './package.json';

const version: string = packageJson.version || 'develop';

const insiteApiUrl: string = import.meta.env.VITE_INSITE_API_URL;
const hasuraApiUrl: string = import.meta.env.VITE_HASURA_URL;
const isDevMode = import.meta.env.DEV;

const sentryDsn = import.meta.env.VITE_SENTRY_DSN;
const sentryEnv = import.meta.env.VITE_SENTRY_ENVIRONMENT;
const sentryRelease = version;

const keycloakUrl: string = import.meta.env.VITE_KEYCLOAK_URL;
const keycloakRealm: string = import.meta.env.VITE_KEYCLOAK_REALM;
const keycloakClientId: string = import.meta.env.VITE_KEYCLOAK_CLIENT_ID;

interface AppConfig {
  api: {
    insite: string;
    hasura: string;
  };
  sentry: {
    dsn?: string;
    release?: string;
    environment?: string;
  };
  keycloak: {
    url: string;
    realm: string;
    clientId: string;
  };
  version: string;
  isDevMode: boolean;
}

export const config: AppConfig = {
  api: {
    insite: insiteApiUrl,
    hasura: hasuraApiUrl,
  },
  sentry: {
    dsn: sentryDsn,
    release: sentryRelease,
    environment: sentryEnv,
  },
  keycloak: {
    url: keycloakUrl,
    realm: keycloakRealm,
    clientId: keycloakClientId,
  },
  version,
  isDevMode,
};
